import { Page } from "components/layout";
import { Image, Typography } from 'antd'

const {Title} = Typography

function SoldOut() {
  return(
    <Page title="City Science Summit Andorra - Registration -" wallpaper="wallpaper_two.jpg">
      <div className="form-container">
        <div style={{alignItems: 'Center', justifyContent: 'center', display:'flex'}}>
          <Image
            src={"jpeg/cross.png"}
            width={'50px'}
          />
          <Title level={2} style={{'marginLeft': '3%'}}><strong> Registration closed!</strong>  </Title>
        </div>
      </div>
    </Page>
  )
}
export default SoldOut